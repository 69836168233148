

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-partner-details-dialog',
  templateUrl: './partner_details.dialog.html'
})

export class PartnerDetailsDialog implements OnInit {


  partner: any = {
    name: null,
    password: null,
    email: null,
    phone: null,
    compact_logo: null,
    contactName: null,
    logoUrl: null,
    colorScheme: [null, null],
    uuid: null,
    key: null,
    domain: null,
    code: null,
    changePassword: false
  }
  isNew = false;
  constructor(public dialogRef: MatDialogRef<PartnerDetailsDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
      if (this.data.isNew) {
        this.isNew = true;
      }else {
        this.isNew = false;
        this.partner = this.data.partner
        this.partner.changePassword = false;
        this.partner.password = '';
      }
  }


  changeCode() {
    this.http.put<any>('/auth/super/restricted/partners/code/' + this.partner.code, {partnerId: this.partner._id}).subscribe(data => {
      this.partner.code = data.code
    }, err => {
      window.alert('Erro na troca de codigo')
    })
  }

  newPassword() {
    this.partner.changePassword = !this.partner.changePassword 
  }

  confirmDialog() {
    if (this.partner.password !== '' && this.partner.password != null) {
      this.partner.changePassword = true;
    }
    if(this.isNew) {
      this.createPartner()
    }else {
      this.updatePartner()
    }
  }

  createPartner() {
    this.http.post('/auth/super/restricted/partner', this.partner).subscribe(data => {
      this.dialogRef.close(data);
    }, err => {
      window.alert('Erro na criação')
    })
  }

  updatePartner() {
    this.http.put('/auth/super/restricted/partner/' + this.partner._id, this.partner).subscribe(data => {
      this.dialogRef.close(data);
    }, err => {
      window.alert('Erro na criação')
    })
  }



  closeDialog(): void {
    this.dialogRef.close(false);
  }


}
