import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { LicenseCrudDialog } from "./dialogs/insert_update_license.dialog";


@Component({
    selector: 'app-super-licenses',
    templateUrl: './super_licenses.component.html'
})

export class SuperLicensesComponent implements OnInit {

    licensesList: any[] = [];
    isActive = true;
    pagingData = {pageIndex: 0, size: 25, total: 0}
    constructor(private http: HttpClient, private dialog: MatDialog) {

    }


    ngOnInit() {
        this.getLicenses()
    }

    changeActive() {
        this.isActive = !this.isActive;
        this.pagingData.pageIndex = 0;
        this.pagingData.total = 0;
        this.getLicenses();
    }


    changePaging(event) {
        console.log(event);
        this.pagingData.size = event.pageSize;
        this.pagingData.pageIndex = event.pageIndex;
        this.getLicenses();
      }


    getLicenses() {
        let page = '&page=' + this.pagingData.pageIndex;
        let size = '&limit=' + this.pagingData.size;

        this.http.get<any>('/auth/super/restricted/licenses/active' + '?active=' + (this.isActive ? '1' : '0') +page + size ).subscribe(data => {
            this.licensesList = data.data;
            this.pagingData.total = data.count;
        })
    }

    insertLicense() {
        let dialogRef = this.dialog.open(LicenseCrudDialog, {
          width: '70%',
          disableClose: true,
          data: {isNew: true}
        })
    
        dialogRef.afterClosed().subscribe(data => {
          if (data) {
            this.licensesList.unshift(data)
          }
        })
      }

      editLicense(license) {
        let dialogRef = this.dialog.open(LicenseCrudDialog, {
          width: '70%',
          disableClose: true,
          data: {isNew: false, license: license}
        })
        
        dialogRef.afterClosed().subscribe(data => {
          if (data) {
            this.licensesList.map(item => {
              if (item._id === data._id) {
                return data
              }else {
                return item
              }
            })
          }
        })
      }

    deleteLicense(item) {
        this.http.delete('/auth/licences/v2/' + item._id).subscribe(data =>{
            this.licensesList.filter(itm => itm._id !== item._id);
        })
    }

    updateLicenses(item) {
        if (new Date(item.expiration) < new Date()) item.active = 0;
        this.http.post<any>('/auth/super/restricted/licenses/active/' + item._id, item).subscribe(data => {

        })
    }

}