import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-production-point',
  templateUrl: './production-point.component.html',
  styleUrls: ['./production-point.component.css']
})
export class ProductionPointComponent implements OnInit {

  list: any[] = [];
  sending = false;
  notSuper = true;
  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : '/' + (localStorage.getItem('masterId'))

    this.http.get(startingUrl + 'crud/productionpoint' + masterId).subscribe((next: any[]) => {
      this.list = next;
    })

  }

  add() {
    this.list.unshift({
      name: '',
      isNew: true,
      status: ""
    });
  }

  modify() {
    
  }

  deleteProductionPoint(item) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'

    this.http.delete<any>(startingUrl + 'crud/productionpoint/' + masterId + item.remoteId).subscribe(data => {
      setTimeout(() => {
        this.sending = false;
      }, 500)
      item.active = 0;
    })
  }

  addPush() {

    this.list.push({
      name: '',
      isNew: true,
    });

  }

  clone(){

  }

  save(item: any) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';

    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 = this.notSuper ? '' : '/' + (localStorage.getItem('masterId'))

    if(!item.isNew){
      delete(item.saving);
      delete(item.checked);
      delete(item.isNew);
      delete(item.selected);
      item.active = true;
      this.http.put(startingUrl + 'crud/productionpoint/' + masterId + item.remoteId, item).subscribe((next: any) => {
        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)
      });

    }else{
      delete(item.saving);
      delete(item.checked);
      delete(item.isNew);
      delete(item.selected);
      item.active = true;
      this.http.post(startingUrl + 'crud/productionpoint' + masterId2, item).subscribe((next: any) => {
        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)
      });
    }

  }


}
