import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomDialog } from '../userDetails/sql_tools/custom_dialog';
import { AppService } from 'src/app/app.service';
import { UserCrudDialog } from '../userDetails/user_crud/user_crud.dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: []
})

export class UsersComponent implements OnInit {

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private appService: AppService, private activatedRoute: ActivatedRoute) { }
  userList: any = [];
  isUser = true;
  searchTerm = '';
  allowBeta = false;
  paramSub: any;
  pagingData = {page: 0, size: 25, total: 100}
  partner: any = {}
  ngOnInit() {

    this.appService.getCurrentPartner(newPartner => {
      this.partner = newPartner;
     })


    this.paramSub = this.activatedRoute.params.subscribe(data => {
      this.isUser = data['isUser'] == 1;
      this.getUsers();

    })
  }


  editUser(userId) {

  }


  editMachines(userId) {
    this.router.navigateByUrl('/super/users/user-details/' + userId);

  }
  resetPassword(itemId) {
    this.http.get('/auth/super/restricted/reset/' + itemId).subscribe(data => {
      
    })
  }

  freeBeta() {
    this.allowBeta = true;
  }


  getPartner() {
    return this.partner
  }

  

  addUser() {
    let dialogRef = this.dialog.open(UserCrudDialog, {
      width: '80%',
      data: {isNew: true, isUser: this.isUser, partnerId: this.partner._id}
    })
    
    dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.userList.unshift(data);
        }
    })
  }

  modifyUser(user, index) {
    console.log('called modify user on index', index)
    let dialogRef = this.dialog.open(UserCrudDialog, {
      width: '80%',
      data: {isNew: false, isUser: this.isUser, partnerId: this.partner._id, userId: user._id}
    })
    
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        console.log('data from update ', data)
        this.userList[index] = data;
      }
    })
  }


  addEvent() {
    let dialogRef = this.dialog.open(CustomDialog, {
      width: '80%',
      data: {
        title: 'Detalhes do evento',
        method: 'post',
        endpoint: '/auth/super/restricted/pdv/create/' + this.partner._id,
        params: '',
        queries: '',
        body: {},
        fields: [
            {
                name: 'name',
                display: 'Nome',
                value: '',
                size: 4,
                type: 'text',
                required: true,
                elementType: 'body'
            },{
                name: 'phone',
                display: 'Telefone',
                value: '',
                size: 3,
                type: 'tel',
                required: true,
                elementType: 'body'
            },{
                name: 'password',
                display: 'Senha',
                value: '',
                size: 3,
                type: 'password',
                required: true,
                elementType: 'body'
            },
            {
              name: 'initialDate',
              display: 'Data inicial',
              value: new Date(),
              type: 'date',
              size: 2,
              required: true,
              elementType: 'body'
          },
          {
            name: 'finalDate',
            display: 'Data Final',
            value: new Date(),
            type: 'date',
            size: 2,
            required: true,
            elementType: 'body'
          },
            {
              name: 'eventAddress',
              display: 'Endereço',
              value: '',
              size: 12,
              type: 'group',
              elementType: 'body',
              children: [
                  {
                    name: 'address',
                    display: 'Endereço',
                    value: '',
                    size: 5,
                    type: 'text',
                    elementType: 'body'
                  },
                  {
                    name: 'addressNumber',
                    display: 'Número',
                    value: '',
                    size: 2,
                    type: 'text',
                    elementType: 'body'
                  },
                  {
                    name: 'complement',
                    display: 'Complemento',
                    value: '',
                    size: 3,
                    type: 'text',
                    elementType: 'body'
                  },
                  {
                    name: 'neighborhood',
                    display: 'Bairro',
                    value: '',
                    size: 2,
                    type: 'text',
                    elementType: 'body'
                  },
                  {
                    name: 'city',
                    display: 'Cidade',
                    value: '',
                    size: 3,
                    type: 'text',
                    elementType: 'body'
                  },
                  {
                    name: 'uf',
                    display: 'UF',
                    value: '',
                    size: 2,
                    type: 'multi',
                    values: [
                      "AC",
                      "AL",
                      "AM",
                      "AP",
                      "BA",
                      "CE",
                      "DF",
                      "ES",
                      "GO",
                      "MA",
                      "MG",
                      "MS",
                      "MT",
                      "PA",
                      "PB",
                      "PE",
                      "PI",
                      "PR",
                      "RJ",
                      "RN",
                      "RO",
                      "RR",
                      "RS",
                      "SC",
                      "SE",
                      "SP",
                      "TO"],
                    elementType: 'body'
                  },
              ]
            }
        ]
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      this.userList.unshift(data);
    })
  }

  changeType() {
    this.isUser = !this.isUser;
    this.pagingData.page = 0;
    this.getUsers();
  }

  getUsers() {
    this.http.get<any>('/auth/super/restricted/users/'+this.partner._id+'?page=' + (this.pagingData.page + 1) + '&limit=' + this.pagingData.size + '&type=' + (this.isUser ? 'user' : 'pdv')).subscribe(data => {
      this.userList = data.docs;
      this.pagingData.total = data.count || 100;
    })
  }

  onSearch() {
    if (this.searchTerm !== '') {
      this.pagingData.total = 0;
      this.pagingData.page = 0;
      this.http.get<any>('/auth/super/restricted/users/search/'+this.partner._id+'?page=' + (this.pagingData.page + 1) + '&limit=' + this.pagingData.size + '&type=' + (this.isUser ? 'user' : 'pdv') + '&searchTerm=' + this.searchTerm).subscribe(data => {
        this.userList = data.docs;
        this.pagingData.total = data.count || 100;
      })
    }else {
      this.pagingData.total = 0;
      this.pagingData.page = 0;
      this.getUsers();
    }

  }

  deleteUser(userId) {
    this.http.delete('auth/super/restricted/user/'+ userId ).subscribe(data => {
      this.userList = this.userList.filter(item => item._id !== userId)
    }, err => {
      window.alert('error removeing user: ' + userId);
    })
  }

  clearMachines() {

  }

  changePaging(event) {
    console.log(event);
    this.pagingData.size = event.pageSize;
    this.pagingData.page = event.pageIndex;
    this.getUsers();
  }

}
