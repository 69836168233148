import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ChartModel} from '../models/chart.model';
import {ChartResponseModel} from "../models/chart-response.model";
import * as moment from 'moment';
import {SocketService} from "../services/socket.service";
import Swal from 'sweetalert2';

declare var ApexCharts : any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboard: ChartResponseModel = new ChartResponseModel();
  filter = {
    id: 2,
    date1: <any> moment().format('YYYY-MM-DD'),
    date2: <any> moment().format('YYYY-MM-DD')
  };

  filters = [
    {
      title: 'Ontem',
      filter: {
        id: 1,
        date1: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        date2: moment().subtract(1, 'days').format('YYYY-MM-DD')
      }
    },
    {
      title: 'Hoje',
      filter: {
        id: 2,
        date1: moment().format('YYYY-MM-DD'),
        date2: moment().format('YYYY-MM-DD')
      }
    },{
      title: 'Semana',
      filter: {
        id: 3,
        date2: moment().endOf('week').format('YYYY-MM-DD'),
        date1: moment().startOf('week').format('YYYY-MM-DD')
      }
    },{
      title: 'Mês',
      filter: {
        id: 4,
        date2: moment().endOf('month').format('YYYY-MM-DD'),
        date1: moment().startOf('month').format('YYYY-MM-DD')
      }
    }
  ];
  changingDate = false;


  constructor(private http: HttpClient, private socket: SocketService) {

    this.socket.on.subscribe(() => {

      this.reload(false);

    });

  }

  ngOnInit() {

    this.reload(true);

    /* window.setInterval(() => {
      this.reload(false);
    }, 5000); */

  }


  getDayPlus() {
    return 'Mudar data para: ' + moment(this.dashboard.meta.date).add(1, 'days').format('DD-MM-YYYY')
  }

  forwardDate(){

    Swal.fire({
      title: 'Deseja mudar a data?',
      text: "Esa ação não podera ser revertida. Esta seguro que deseja mudar a data?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        let newDate = moment(this.dashboard.meta.date).add(1, 'days').toDate().toISOString();
        this.changingDate = true;
        this.http.post('/restricted/forward-date/', {date: moment(this.dashboard.meta.date).add(1, 'days').format('"YYYY-MM-DD"')}).subscribe(data => {
          this.changingDate = false;
          this.dashboard.meta.date = newDate;
        }, err => {
          this.changingDate = false;
        })
      }
    })

  }


  reload(fromZero: boolean) {

    let date1 = this.filter.date1;
    let date2 = this.filter.date2;
    console.log(typeof this.filter.date1, typeof this.filter.date2)
    if (typeof this.filter.date1 === 'object') {




        const convertedDate = moment(this.filter.date1).format('YYYY-MM-DD');


        date1 = convertedDate;

      

    }

    if (typeof this.filter.date2 === 'object') {


        const convertedDate = moment(this.filter.date2).format('YYYY-MM-DD');


        date2 = convertedDate;

      

    }



    this.http.get<ChartResponseModel>(`/restricted/data?date1=${date1}&date2=${date2}`).subscribe((a: ChartResponseModel) => {


      this.dashboard.meta = a.meta;

      if(this.dashboard.charts.length === 0 || fromZero) {


        this.dashboard = a;

      } else {

        this.dashboard.meta = a.meta;

        this.dashboard.charts.forEach((chart, k) => {
            if (chart.title === 'Mesas') {
              this.dashboard.charts[k] = a.charts[k]
            }else {

              if (
                JSON.stringify(chart.options.series) !== JSON.stringify(a.charts[k].options.series || a.charts[k].options.chart.type === 'cols')
              ) {

                chart.onNewData(a.charts[k]);
              }
              
            }



        });

      }
    });
  }

}
