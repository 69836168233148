import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-paymentmethod',
  templateUrl: './list-payment-methods.component.html',
  styleUrls: ['./list-payment-methods.component.css']
})
export class ListPaymentMethodsComponent implements OnInit {

  list: any[] = [];
  symbols= ['BRL', 'PYG', 'ARS', 'USD', 'EUR']
  sending = false;
  notSuper = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService
  ) { }




  ngOnInit() {
    
    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    
    let masterId = this.notSuper ? '' : '/' + (localStorage.getItem('masterId')) 

    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    this.http.get(startingUrl + 'crud/paymentmethod' + masterId).subscribe((next: any[]) => {

      this.list = next;
    })

  }

  add() {
    this.list.unshift({
      name: '',
      isNew: true,
    });
  }

  modify() {
    
  }

  deleteProductionPoint(item) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' :  (localStorage.getItem('masterId')) + '/'

    this.http.delete<any>(startingUrl + 'crud/paymentmethod/' + masterId + item.remoteId).subscribe(data => {
      item.active = 0;
      setTimeout(() => {
        this.sending = false;
      }, 500)
    })
  }

  addPush() {

    this.list.push({
      name: '',
      isNew: true,
    });

  }

  clone(){

  }

  save(item: any) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 = this.notSuper ? '' : '/' +(localStorage.getItem('masterId')) + '/'

    if(!item.isNew){
        delete(item.saving);
        delete(item.checked);
        delete(item.isNew);
        delete(item.selected);
        item.active = true;
        item.allowOffset = 0;

      this.http.put(startingUrl + 'crud/paymentmethod/' + masterId + item.remoteId, item).subscribe((next: any) => {
        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)
      });

    }else{
        delete(item.saving);
        delete(item.checked);
        delete(item.isNew);
        delete(item.selected);
        item.active = true;
        item.allowOffset = 0;
      this.http.post(startingUrl + 'crud/paymentmethod' + masterId2, item).subscribe((next: any) => {
        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)
      });
    }

  }


  setBaseCurrency(item) {
    this.list.forEach(item => {
      item.baseCurrency = 0;
    });

    item.baseCurrency = 1;
  }

  changeOperation(item) {
    if (item.operation === 1) {
      item.operation = 0;
    } else {
      item.operation = 1;

    }
  }


}
