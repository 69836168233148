import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { AppService } from "src/app/app.service";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'app-super-login',
    templateUrl: './super_login.component.html'
})

export class SuperLoginComponent implements OnInit {

    constructor (private http: HttpClient, private auth: AuthService, private router: Router, private appService: AppService, private formBuilder: FormBuilder) {
    }

    changePassword = false;

    verification = {
        number1: Math.floor(Math.random() * (10 - 1 + 1) + 1),
        number2: Math.floor(Math.random() * (10 - 1 + 1) + 1),
        operator: '+'
    }


    credentials:any = {
        username: '',
        password: '',
        code: null
    }

    ngOnInit() {
    }

    login() {
        if (this.verifyCode()) {
            this.http.post<any>('/auth/super/login', this.credentials).subscribe(data => {
                this.auth.setToken(data.token);
                this.router.navigateByUrl('/super/users/1');
                this.appService.setSuperLogged(true);
            }, err => {
                this.showError('Usuário ou senha incorretos');
            })
        } else {
            this.showError();
        }
    }

    showError(body?) {
        Swal.fire('Erro', body || 'codigo de verificação incorreto', 'error');
    }

    verifyCode() {
        if (this.verification.operator == '-') {
            return Math.abs(this.verification.number1 - this.verification.number2) === Math.abs(this.credentials.code);
        } else {
            return this.verification.number1 + this.verification.number2 === this.credentials.code;
        }
    }


}