import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent implements OnInit {

  constructor(private router: Router, private appService: AppService) { }

  notSuper = true;
  ngOnInit() {

    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    }

}
