import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef, MatStepper} from '@angular/material';
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
    selector: 'app-buy-license',
    templateUrl: './buy-license.dialog.html',
    styleUrls: ['./buy-license.dialog.css']
})


export class BuyLicenseDialog implements OnInit {

    invoices = [];
    years = [2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034];
    months = [1,2,3,4,5,6,7,8,9,10,11,12];
    creditCards = [{cardEnding: "**54", _id: ""}]
    creditCardForm: FormGroup;
    
    // new model

    paymentUrl: any = '';
    creating = true;

    @ViewChild('stepper')
    stepper: MatStepper 

    constructor(public dialogRef: MatDialogRef<BuyLicenseDialog>,
        private http: HttpClient,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        console.log(this.data)


        
        const token = this.data.queries.token
        const [headerEncoded, payloadEncoded, signature] = token.split('.')
        const payload = this.decodeTokenComponent(payloadEncoded);


        this.http.post<any>('/auth/licences/use/' + payload.id + '/' + this.data.id, {}).subscribe(data => {
            console.log(data, 'DATA RESPONSE LICENSE USE SERIAL ID LICENSE ID =====');
            var callback = {
                success : function(transactionCode) {
                    //Insira os comandos para quando o usuário finalizar o pagamento. 
                    //O código da transação estará na variável "transactionCode"
                    console.log("Compra feita com sucesso, código de transação: " + transactionCode);
                },
                abort : function() {
                    //Insira os comandos para quando o usuário abandonar a tela de pagamento.
                    console.log("abortado");
                }
            };

            var isOpenLightbox = (<any>window).PagSeguroLightbox(data.code, callback);
            if (!isOpenLightbox){
                console.log('Not suported')
            }
        })
        // this.invoices.push(this.data)
        this.creditCardForm = this.fb.group({
            creditCardNumber: ['', Validators.required],
            cvc: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(3), Validators.required])],
            holder: ['', Validators.required],
            month: ['', Validators.required],
            year: ['', Validators.required]
        })
        
    }


     decodeTokenComponent(value) {
        const text = atob(value)
        return JSON.parse(text)
    }

    getLastCCDigits() {
        return '**** **** **** ' + this.creditCardForm.getRawValue().creditCardNumber.substring(
            this.creditCardForm.getRawValue().creditCardNumber.length - 4, this.creditCardForm.getRawValue().creditCardNumber.length
        )
    }

    sumInvoices() {
       return this.invoices.reduce((prevVal, currVal, index) => {
            return prevVal += (currVal.amount * currVal.qty)
        }, 0)
    }

    closeDialog() {
        this.dialogRef.close()
    }
    
    confirmatePayment() {

    }

    addQty(item) {
            item.qty += 1
    }

    removeQty(item) {
        if (item.qty > 1) {
            item.qty -= 1
        }
    }

    nextStep() {
        this.stepper.next()
    }

    prevStep() {
        this.stepper.previous()
    }
}