import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import {COMMA, ENTER} from "@angular/cdk/keycodes";


@Component({
  selector: 'app-enterprise-config-dialog',
  templateUrl: 'enterprise-configurations.dialog.html'
})

export class EnterpriseConfigurationsDialog implements OnInit {

  enterpriseConfig: any = {};
  categories = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(public dialogRef: MatDialogRef<EnterpriseConfigurationsDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.enterpriseConfig = this.data.enterprise
  }

  confirmateDialog() {
    this.http.put<any>('/restricted/crud/enterprise/' + this.enterpriseConfig.remoteId, this.enterpriseConfig).subscribe(data => {
      this.dialogRef.close();
    })
  }

  changeDateConfig() {
    if (this.enterpriseConfig.autoChangeDate === 1) {
      this.enterpriseConfig.autoChangeDate = 0;
    }else {
      this.enterpriseConfig.autoChangeDate = 1;

    }
  }

  addCategory(event) {

  }

  removeCategory(item) {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
