export class ChartModel {

  col: any;

  colMd: number;

  colSm: number;

  colXs: number;

  title: string;

  options: {
    chart: {
      type: 'bar' | 'pie' | 'box' | 'cols' | 'tables',
      height: number
    },
    columns: {
      format?: string,
      col: any,
      value: any | any[],
      title: string,
      number: any,
      status: any
    }[],
    series: any,
    xaxis: any
  };

  onNewData = (data) => {

  }

}
