import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { LicenseDetailsDialog } from "./dialogs/license-details.dialog";
import { BuyLicenseDialog } from "./dialogs/buy-license.dialog";
import { isNullOrUndefined } from "util";
import { HttpClient } from "@angular/common/http";



@Component({
    selector: 'app-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.css']
})


export class LicensesComponent implements OnInit, AfterViewInit {

    
    ownedLicenses = [
        {
            title: 'Assinatura de Entrada',
            description: 'Inclui Caixa, Atendimento, Mesas, PDV balcão, PDV Ticket Bar e Delivery. Modulo de Emissão de notas, ate 100 notas (para mais notas verifique os pacotes adicionais). Modulo Web Gerencial.',
            fullDescription: 'Inclui Caixa, Atendimento, Mesas, PDV balcão, PDV Ticket Bar e Delivery. Modulo de Emissão de notas, ate 100 notas (para mais notas verifique os pacotes adicionais). Modulo Web Gerencial.',
            payments: [
                {date: '19/06/2019', qty: 1, price: 19.99, total: 19.99, card: '**** **** **** 2103', name: 'Assinatura de Entrada'}
            ],
            expiration: '19/08/2019',
            threshold: '19/08/2019',
            type: 'montly'
        }, 
        {
            title: 'Notas Fiscais',
            description: 'Disponiveis 173 notas fiscais para emissão',
            fullDescription: 'Disponiveis 173 notas fiscais para emissão',
            payments: [],
            expiration: 173,
            threshold: 10,
            type: 'quantity'
        }, 

    ];

    /*

    licence{
        _id: '32kjk213j23k21j',
        name: 'Licença uso',
        amount: 4.99,
        type: 'monthly' | 'quantity'
    }

    */

    availableLicenses: any = [];
    
    params: any = {};
    queries: any = {}
    constructor(private router: Router, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private http: HttpClient) {
        

    }

   

    ngAfterViewInit() {
        
        setTimeout(() => {

            console.log(!isNullOrUndefined(this.params.id), 'is not null nor undefined', this.params.id)
            if (!isNullOrUndefined(this.params.id)) {
                if (this.queries.token) {
                    localStorage.setItem('token', this.queries.token);
                }
    
                var callback = {
                    success : (transactionCode) => {
                        console.log("Success on transaction ");
                        window.close();
                    },
                    abort : () => {
                        console.log("aborted");
                        window.close();
                    }
                };
                var isOpenLightbox = (<any>window).PagSeguroLightbox(this.queries.code, callback);
                if (!isOpenLightbox){
                    location.replace("https://sandbox.pagseguro.uol.com.br/v2/checkout/payment.html?code=" + this.queries.code)
                }
    
    
                /*
                    let dialogRef = this.dialog.open(BuyLicenseDialog, {
                        width: '95%',
                        data: {id: this.params.id, queries: this.queries},
                        disableClose: true
                    })
            
                    dialogRef.afterClosed().subscribe(data => {
            
                    })         
                */
            }


        }, 500)
        

    }

    ngOnInit() {

        this.http.get('/auth/licences').subscribe(data => {
            this.availableLicenses = data;
        })

        this.activatedRoute.params.subscribe(params => {
            console.log('PARAM!!!!! ', params)
            this.params = params
        })
        this.activatedRoute.queryParams.subscribe(query => {
            this.queries = query;
            console.log(this.queries)
        })

       /* console.log(!isNullOrUndefined(this.params.id), 'is not null nor undefined', this.params.id)
        if (!isNullOrUndefined(this.params.id)) {
            if (this.queries.token) {
                localStorage.setItem('token', this.queries.token)
            }

            var callback = {
                success : function(transactionCode) {
                    //Insira os comandos para quando o usuário finalizar o pagamento. 
                    //O código da transação estará na variável "transactionCode"
                    console.log("Compra feita com sucesso, código de transação: " + transactionCode);
                },
                abort : function() {
                    //Insira os comandos para quando o usuário abandonar a tela de pagamento.
                    console.log("abortado");
                }
            };
            //Chamada do lightbox passando o código de checkout e os comandos para o callback
            var isOpenLightbox = (<any>window).PagSeguroLightbox(this.queries.code, callback);
            // Redireciona o comprador, caso o navegador não tenha suporte ao Lightbox
            if (!isOpenLightbox){
                location.href="https://pagseguro.uol.com.br/v2/checkout/payment.html?code=" + this.queries.code;
            }


                 
        }*/

        // TODO push replace
        //this.router.navigate(['/view'], { replaceUrl: true });

    }



    licenseDetails(item) {
        let dialogRef = this.dialog.open(LicenseDetailsDialog, {
            width: '60%',
            data: item
        })

        dialogRef.afterClosed().subscribe(data => {

        })
    }
    

    buyLicense(item) {
        let dialogRef = this.dialog.open(BuyLicenseDialog, {
            width: '99%',
            data: {id: item._id, queries: this.queries}
        })

        dialogRef.afterClosed().subscribe(data => {

        })  
    }

    getTagType(item) {
        if (item.type === 'montly' || item.type === 'yearly') {
            return item.threshold <= item.expiration ? 'warn' : 'success'
        }else {
            return item.threshold >= item.expiration ? 'warn' : 'success'

        }
    }




}