

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom_dialog.html'
})

export class CustomDialog implements OnInit {



  constructor(public dialogRef: MatDialogRef<CustomDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }



  ngOnInit() {

    }


  executeFunction() {
    if (this.data.method == 'post' || this.data.method == 'put') {
        let params = this.data.params;
        let queries = this.data.queries;
        let body = this.data.body;
        this.data.fields.forEach(element => {
            if (element.elementType === 'param') {
                params += '/' + element.value
            } else {
                if (element.elementType === 'body') {
                    if (element.type == 'group') {
                        element.children.forEach(child => {
                            body[child.name] = child.value;
                        })

                    }else {
                        body[element.name] = element.value;
                    }
                }else {
                    if (queries.length<1) {
                        queries = ('?' + element.name + '=' + element.value)
                    } else {
                        queries += ('&' + element.name + '=' + element.value)
                    }
                }

            }
        });
        this.http[this.data.method](this.data.endpoint + params + queries, body).subscribe(data => {
            this.dialogRef.close(data)
        })
    
    }else {
        let params = this.data.params;
        let queries = this.data.queries;
        this.data.fields.forEach(element => {
            if (element.elementType === 'param') {
                params += '/' + element.value
            }else {
                if (queries.length<1) {
                    queries = ('?' + element.name + '=' + element.value)
                } else {
                    queries += ('&' + element.name + '=' + element.value)
                }
            }
        });
        console.log('will execute: ', this.data.endpoint + params + queries)
        this.http[this.data.method](this.data.endpoint + params + queries).subscribe(data => {
            this.dialogRef.close(data)
        })
    }
  }


  cancelDialog() {
      this.dialogRef.close(null)
  }

}
