import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SocketService} from "../services/socket.service";
import { HttpClient } from '@angular/common/http';
import { SelectMachineDialogComponent } from '../select-machine-dialog/select-machine-dialog.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
import { AppService } from '../app.service';
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css']
})
export class QrcodeComponent implements AfterViewInit, OnInit {

  @ViewChild('mmm')
  imgQrcode: ElementRef;

  src = '';

  numericId = '0 0 0 0';

  state = {
    from: 'login-form',
    phone: '',
    password: '',
    inverse: true
  };

  partner:any = {}

  constructor(
    private socket: SocketService,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private appService: AppService
  ) {

    if (window.localStorage.getItem('_token')) {
      this.router.navigate(['/dashboard']);
    }

   }

   ngOnInit() {
     this.appService.getCurrentPartner(newPartner => {
      this.partner = newPartner;
     })
   }


   
  ngAfterViewInit() {


    this.socket.emit('get_token', {type: 'client'}, () => {


      this.socket.onMessage('token_ready', (token) => {

        this.numericId = token.numericId;

        this.imgQrcode.nativeElement.src = token.dataUrl;

        // this.src = token.dataUrl;

        window.localStorage.setItem('_temp_token', token.token);

        this.socket.onMessage('dabase_uploaded', (msg) => {

          // new token generated
          window.localStorage.setItem('_token', msg.token);

          window.location.href = 'dashboard/';

        });

      });

    });
  }

  login() {

    if (this.state.phone == '' || this.state.password == '') return;
    
    this.http.post('/auth/', this.state).subscribe((resp: any) => {
      this.appService.setMaster(resp.tokens);

      window.localStorage.setItem('_machines', JSON.stringify(resp.tokens));

      this.dialog.open(SelectMachineDialogComponent, {
        width: '700px',
        data: {
          machines: resp.tokens
        }
      });

    }, err => {

      Swal.fire('Erro', 'Usuário ou senha incorretos', 'error');

    });

  }

}
