import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-list-menu-item-group',
  templateUrl: './list-menu-item-group.component.html',
  styleUrls: ['./list-menu-item-group.component.css']
})
export class ListMenuItemGroupComponent implements OnInit {

  list: any[] = [];

  enterprises: any[] = [];

  sending = false;

  notSuper = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {

    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : '/' + (localStorage.getItem('masterId'));

    this.http.get(startingUrl + 'crud/menuitemgroup' + masterId).subscribe((next: any[]) => {
      this.list = next;


      this.http.get(startingUrl + 'crud/enterprise' + masterId).subscribe((next: any[]) => {

        this.enterprises = next;

      });

    });

  }

  add() {
    this.list.unshift({
      name: '',
      isNew: true,
      active: true

    });
  }


  deleteGroupItem(item) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'

    this.http.delete<any>(startingUrl +'crud/menuitemgroup/' + masterId + item.remoteId).subscribe(data => {
      setTimeout(() => {
        this.sending = false;
      }, 500)
      item.active = 0;
    })
  }

  addPush() {

    this.list.push({
      name: '',
      isNew: true,
      active: true
    });

  }

  clone(){

  }


  modify() {
    
  }
  

  save(item: any) {
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 = this.notSuper ? '' : '/' + (localStorage.getItem('masterId'));
    this.sending = true;
    if(!item.isNew){
      delete(item.saving);
      delete(item.checked);
      delete(item.isNew);
      delete(item.selected);
      item.active = true;
      this.http.put(startingUrl + 'crud/menuitemgroup/' + masterId + item.remoteId, item).subscribe((next: any) => {

        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)

      });

    }else{
      delete(item.saving);
      delete(item.checked);
      delete(item.isNew);
      delete(item.selected);
      item.active = true;

      this.http.post(startingUrl + 'crud/menuitemgroup' + masterId2, item).subscribe((next: any) => {
        item = next;
        setTimeout(() => {
          this.sending = false;
        }, 500)
      });
    }

  }

}
