import { Injectable } from '@angular/core';
import {AuthService} from './auth.service';
import {Subject} from 'rxjs/index';

declare const io: any;

const TAG = 'SOCKET_IO';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  on: Subject<any>;

  onSocketDestroyed: Subject<any>;

  socket: any;

  constructor(public auth: AuthService) {

    this.on = new Subject();

    this.onSocketDestroyed = new Subject();

    this.connect();

  }

  emit(event: string, data: any, callback: Function) {
    this.socket.emit(event, data);
    callback();
  }

  onMessage(event: string, callback: Function) {

    this.socket.once(event, callback);

  }

  offMessage (event: string) {
    this.socket.off(event);
  }

  connect() {


    let uri = window.location.origin;

    if (window.location.port === '4200') {

      uri = 'http://' + window.location.hostname + ':3434';

    }

    if (window.location.port === '5050') {

      uri = 'http://192.168.1.145:3434';

    }

    if(io) {

      this.socket = io.connect(uri);

      this.socket.on('new_data', (data: any) => {


        this.on.next(data);

      });

      this.socket.on('socket_destroyed', () => {

        this.onSocketDestroyed.next('');


      });

      this.socket.emit('set_client_id', {token: this.auth.getToken(), type: 'client'});

      this.socket.on('connection_ok', () => {

        /*console.log(TAG, 'connectionOK -> true');

        this.socket.emit('get_data', {table: 'menuitem'});

        this.socket.on('got_data', (data: any) => {

          console.log(TAG, 'got_data', data);

        });*/

      });

      this.socket.on('disconnect', () => {


        /*setTimeout(() => {

          this.connect();

        }, 10000);*/

      });

    }

  }

}
