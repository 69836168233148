

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import { element } from '@angular/core/src/render3/instructions';


@Component({
  selector: 'app-sql-tools-dialog',
  templateUrl: './sql_tools.dialog.html'
})

export class SqlToolsDialog implements OnInit {


  database: any = {};
  sqlField = '';
  sqlResponse = '';
  commandType = 'all';
  toSync = false;
  errorOnQuery = false;
  sqlHistory = []

  constructor(public dialogRef: MatDialogRef<SqlToolsDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }



  ngOnInit() {
     this.database = this.data.database;
  }

  toSyncSlide(){
      this.toSync = !this.toSync;
  }

  addToFavorites() {
    this.sqlHistory.unshift({sqlField: this.sqlField, commandType: this.commandType, toSync: this.toSync});
  }

  selectFavorite(index) {
    this.sqlField = this.sqlHistory[index].sqlField;
    this.toSync = this.sqlHistory[index].toSync;
    this.commandType = this.sqlHistory[index].commandType;
  }

  removeFromFavorites(index) {
    this.sqlHistory.splice(index, 1);
  }

  executeSql() {
    this.http.post<any>('/auth/super/restricted/database/sql/' + this.database._id, {
        sql: this.sqlField,
        type: this.commandType,
        sync: this.toSync
    }).subscribe(data => {
        this.sqlResponse = data.result;
        this.errorOnQuery = data.error;
    })
  }

  getScheme() {
    this.http.get('/auth/super/restricted/generate/ddl/' + this.database._id, {responseType: 'text'}).subscribe(data => {
        this.sqlResponse = data;
    }, err => {
      this.sqlResponse = err;
      console.log(err)
    })
  }


}
