import {Component, Inject, Injectable} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-modify-component',
  template: `
    <div mat-dialog-title>Alterar itens selecionados</div>
    
    <div mat-dialog-content>

      <div style="display: inline-block">
        <mat-checkbox [(ngModel)]="model.isGroup"></mat-checkbox>
      </div>
      
      <mat-form-field>
        <label>Grupo</label>
        <mat-select [(ngModel)]="model.group">
          <mat-option *ngFor="let gp of groups" [value]="gp.remoteId">{{gp.name}}</mat-option>
        </mat-select>
      </mat-form-field>


      <div style="display: inline-block">
        <mat-checkbox [(ngModel)]="model.isPrice"></mat-checkbox>
      </div>
  
      <mat-form-field>
        <input type="text" [disabled]="!model.isPrice" matInput placeholder="Preco" currencyMask [(ngModel)]="model.price" />
      </mat-form-field>

    </div>

    <div mat-dialog-actions>
      <button mat-button color="primary" (click)="cancel()">Voltar</button>
      <button mat-button color="primary" (click)="close()">Confirmar</button>
    </div>
  `
})
export class ModifyDialogComponent {

  groups: any[] = [];

  model: {
    isGroup: boolean,
    group: string,
    isPrice: boolean,
    price: number
  } = {
    isGroup: false,
    group: '',
    isPrice: false,
    price: 0
  };

  constructor(private dialog: MatDialogRef<ModifyDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private cli: HttpClient) {

    this.cli.get('/restricted/crud/menuitemgroup').subscribe((arr: any[]) => {

      this.groups = arr;

    });

  }

  close() {

    this.dialog.close(this.model);

  }

  cancel() {

    this.dialog.close(null);

  }

}
