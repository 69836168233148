import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import { tap } from "rxjs/operators";
import has = Reflect.has;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor (private auth: AuthService, private router: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let url = request.url;

    if (window.location.port === '5050') {
      url = 'https://localhost:3434' + request.url;
    } else if (window.location.port === '2323') {
      url = 'https://' + window.location.hostname  + request.url;
    }

    request = request.clone({
      url: url,
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    return next.handle(request)
/*
    return next.handle(request).pipe(
      tap(
        event => this.handleResponse(request, event),
        error => this.handleError(request, error)
      )
    )*/

  }





}
