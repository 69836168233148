import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MAT_DATE_LOCALE, MatRadioModule, MatBadgeModule, MatStepperModule, MatPaginator, MatPaginatorModule, MatSlideToggleModule, MatTooltipModule} from '@angular/material';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './services/auth.interceptor';
import {AuthService} from './services/auth.service';
import { ChartComponent } from './chart/chart.component';
import { ChartdirectiveDirective } from './chartdirective.directive';
import {SocketService} from './services/socket.service';
import { ListMenuItemComponent } from './crud/list-menu-item/list-menu-item.component';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule, Routes, ActivatedRoute} from '@angular/router';
import {
  MatButton,
  MatButtonModule,
  MatCheckbox,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatSnackBarModule,
  MatCardModule,
  MatListModule,
  MatChipsModule, MatIconModule,MatDateFormats,MAT_DATE_FORMATS
} from '@angular/material';
import {FormsModule, FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import { QrcodeComponent } from './qrcode/qrcode.component';
import {DecimalPipe, registerLocaleData} from '@angular/common';
import localePT from '@angular/common/locales/pt';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {ModifyDialogComponent} from "./crud/list-menu-item/modify-dialog.component";
import { ListMenuItemGroupComponent } from './crud/list-menu-item-group/list-menu-item-group.component';
import { CrudComponent } from './crud/crud/crud.component';
import { ProductionPointComponent } from './crud/production-point/production-point.component';
import { ListEnterpriseComponent } from './crud/list-enterprise/list-enterprise.component';
import { SelectMachineDialogComponent } from './select-machine-dialog/select-machine-dialog.component';
import {EnterpriseConfigurationsDialog} from "./crud/list-enterprise/dialogs/enterprise-configurations.dialog";
import { PolicyComponent } from './policies/policy.component';
import { ListObservationsComponent } from './crud/list-observations/list-observations.component';
import { ListPaymentMethodsComponent } from './crud/list-payment-methods/list-payment-methods.component';
import { LicensesComponent } from './licenses/licenses.component';
import { LicenseDetailsDialog } from './licenses/dialogs/license-details.dialog';
import { BuyLicenseDialog } from './licenses/dialogs/buy-license.dialog';
import { SuperComponent } from './super/super.component';
import { UsersComponent } from './super/users/users.component';
import { SuperLoginComponent } from './super/login/super_login.component';
import { UserDetailsComponent } from './super/userDetails/user_details.component';
import { AppService } from './app.service';
import { PartnersComponent } from './super/partners/partners.component';
import { PartnerDetailsDialog } from './super/partners/partner-details/partner_details.dialog';
import { SqlToolsDialog } from './super/userDetails/sql_tools/sql_tools.dialog';
import { CustomDialog } from './super/userDetails/sql_tools/custom_dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import { SuperLicensesComponent } from './super/licenses/super_licenses.component';
import { LicenseCrudDialog } from './super/licenses/dialogs/insert_update_license.dialog';
import { ReportsComponent } from './reports/reports.component';
import { CustomReport } from './reports/dialogs/custom_report.dialog';
import { TotalReportDialog } from './super/userDetails/sql_tools/total_report.dialog';
import { UserCrudDialog } from './super/userDetails/user_crud/user_crud.dialog';

registerLocaleData(localePT, 'pt-BR');

export const MY_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};


const routes: Routes = [
  {
    path: '',
    component: QrcodeComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'licenses',
    component: LicensesComponent
  },  
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'licenses/:id',
    component: LicensesComponent
  },
  {
    path: 'policy',
    component: PolicyComponent
  },
  {
    path: 'super',
    component: SuperComponent,
    children: [
      {
        path: '',
        component: SuperLoginComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'login',
        component: SuperComponent
      },
      {
        path: 'users/:isUser',
        component: UsersComponent
      },
      {
        path: 'partners',
        component: PartnersComponent
      },
      {
        path: 'users/user-details/:id',
        component: UserDetailsComponent
      },
      {
        path: 'licenses',
        component: SuperLicensesComponent
      },
      {
        path: 'menuitem',
        component: ListMenuItemComponent
      },
      {
        path: 'crud',
        component: CrudComponent,
        children: [
          {
            path: '',
            component: ListMenuItemComponent
          },
          {
            path: 'menu-item',
            component: ListMenuItemComponent
          },
          {
            path: 'menu-item-group',
            component: ListMenuItemGroupComponent
          },
          {
            path: 'production-point',
            component: ProductionPointComponent
          },
    
          {
            path: 'enterprise',
            component: ListEnterpriseComponent
          },
          {
            path: 'observations',
            component: ListObservationsComponent
          },
          {
            path: 'paymentmethods',
            component: ListPaymentMethodsComponent
          },
        ]
      }
    ]

  },
  {
    path: 'crud',
    component: CrudComponent,
    children: [
      {
        path: '',
        component: ListMenuItemComponent
      },
      {
        path: 'menu-item',
        component: ListMenuItemComponent
      },
      {
        path: 'menu-item-group',
        component: ListMenuItemGroupComponent
      },
      {
        path: 'production-point',
        component: ProductionPointComponent
      },

      {
        path: 'enterprise',
        component: ListEnterpriseComponent
      },
      {
        path: 'observations',
        component: ListObservationsComponent
      },
      {
        path: 'paymentmethods',
        component: ListPaymentMethodsComponent
      },
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ChartComponent,
    ChartdirectiveDirective,
    ListMenuItemComponent,
    ListObservationsComponent,
    QrcodeComponent,
    ModifyDialogComponent,
    ListMenuItemGroupComponent,
    CrudComponent,
    ProductionPointComponent,
    ListEnterpriseComponent,
    SelectMachineDialogComponent,
    EnterpriseConfigurationsDialog,
    ListPaymentMethodsComponent,
    PolicyComponent,
    LicensesComponent,
    LicenseDetailsDialog,
    BuyLicenseDialog,
    SuperComponent,
    UsersComponent,
    SuperLoginComponent,
    UserDetailsComponent,
    PartnerDetailsDialog,
    PartnersComponent,
    SqlToolsDialog,
    CustomDialog,
    SuperLicensesComponent,
    LicenseCrudDialog,
    ReportsComponent,
    CustomReport,
    TotalReportDialog,
    UserCrudDialog

  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    CurrencyMaskModule,

    // Material
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSnackBarModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatRadioModule,
    MatBadgeModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ColorPickerModule,
    MatTooltipModule,
    MatListModule
  ],
  entryComponents: [
    ModifyDialogComponent,
    SelectMachineDialogComponent,
    EnterpriseConfigurationsDialog,
    LicenseDetailsDialog,
    BuyLicenseDialog,
    PartnerDetailsDialog,
    SqlToolsDialog,
    CustomDialog,
    LicenseCrudDialog,
    CustomReport,
    TotalReportDialog,
    UserCrudDialog

  ],
  providers: [
    AuthInterceptor,
    AuthService,
    SocketService,
    DecimalPipe,
    FormBuilder,
    AppService,
    Location,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
