import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: 'app-total-report',
    template: `
                <div class="row text-center" mat-dialog-title>
                    Relatório de Vendas
                </div>
                <div class="row" mat-dialog-content>
                    <div class="row">
                        <div class="col-12">
                            <b>Evento: {{data.event.name}}</b>
                            <b style="float: right">Data do Evento: {{data.event.date | date: 'shortDate'}}</b>
                        </div>
                        <div class="col-12">
                            <b>Operador: {{data.title == data.event.name ? 'Evento' : data.title}}</b>
                            <b style="float: right">Cidade: {{data.event.city}}</b>
                        </div>
                    </div>

                    <table class="table table-condensed">
                        <thead>
                        <tr><th>Descrição</th><th>Qdade</th><th>Tipo</th><th style="text-align: right">Total</th></tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of finalRows">
                                <td>{{item.name}}</td>
                                <td>{{item.qty}}</td>
                                <td>{{item.paymentMethod}}</td>
                                <td style="text-align: right">{{item.total | currency: (item.iso !== null ? item.iso : 'BRL') }}</td>
                            </tr>
                            <tr> 
                                <td colspan="3"></td>
                                <td colspan="1"><span style="text-align: right; font-weight: bolder; float: right">Total: {{finalSum.total | currency: 'BRL'}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    `
})


export class TotalReportDialog implements OnInit {

    finalRows = [];
    finalSum = {};

    constructor(public dialogRef: MatDialogRef<TotalReportDialog>,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }


    ngOnInit() {
        this.http.get<any>(this.data.url + '/' + this.data.dbId + '?isMaster=' + (this.data.isMaster ? '1' : '0')).subscribe(data => {
            this.finalRows = data.rows;
            this.finalSum = data.sum
        })
    }



} 