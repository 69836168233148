import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HttpClient } from "@angular/common/http";


@Component({
    selector: 'app-user-crud-dialog',
    templateUrl: './user_crud.dialog.html',
    styleUrls: ['./user_crud.dialog.css']
})


export class UserCrudDialog implements OnInit {
    user = new UserModel;
    isLoading = true;
    currDate = new Date();
    errorGettingDetails = false;
    ufList = [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO"];
    
    constructor(public dialogRef: MatDialogRef<UserCrudDialog>,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.isLoading = true;
        if (this.data.isNew) {
            this.user = new UserModel;
            this.isLoading = false;
        } else {
            this.http.get<any>('/auth/super/restricted/find/user?partnerId=' + this.data.partnerId + (this.data.isNew ? '' : '&userId=' + this.data.userId)).subscribe(data=> {
                if (!data.address) {
                    data.address = new AddressModel();
                }
                this.user = data;
                this.isLoading = false;
            },
            err => {
                this.isLoading = false;
                this.errorGettingDetails = true;
            })
        }
    }


    saveUser() {
        this.http.put<any>('/auth/super/restricted/'+(this.data.isUser ? 'user' : 'event')+'/upsert?partnerId=' + this.data.partnerId +  '&userId=' + this.data.userId, this.user).subscribe(data => {
            this.closeDialog(true, data);
        });
    }

    postUser() {
        this.http.post<any>('/auth/super/restricted/'+(this.data.isUser ? 'user' : 'event')+'/upsert?partnerId=' + this.data.partnerId, this.user).subscribe(data => {
            this.closeDialog(true, data);
        });
    }
    
    changeMiniAccount() {
        this.user.miniAccount = !this.user.miniAccount
    }

    cancelDialog():void {
        this.closeDialog(false)
    }

    closeDialog(result?: Boolean, response?: any): void {
        this.dialogRef.close(result ? response : false);
    }

} 


export class UserModel {
    name: string;
    phone: string;
    initialDate: Date;
    finalDate: Date;
    password: string;
    type: string;
    login: string;
    miniAccount: Boolean;
    address: AddressModel;


    constructor(name?:string, phone?:string, initialDate?: Date, finalDate?: Date, type?: string, password?: string, login?: string, miniAccount?: Boolean, address?: AddressModel) {
        this.name = name || '';
        this.phone = phone || '';
        this.initialDate = initialDate || new Date();
        this.finalDate = finalDate || new Date();
        this.type = type || '';
        this.password = password || '';
        this.login = login || '';
        this.miniAccount = miniAccount || false;
        this.address = address || new AddressModel();
    }



}


export class AddressModel {
    addressLineOne: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    uf: string;
    constructor(addressLineOne?: string, number?:string, complement?:string, neighborhood?:string, city?:string, uf?:string) {
        this.addressLineOne = addressLineOne || '';
        this.number = number || '';
        this.complement = complement || '';
        this.neighborhood = neighborhood || '';
        this.city = city || '';
        this.uf = uf || '';
    }
}