
import {ChartModel} from "./chart.model";

export class ChartResponseModel {

  meta: {
    title: string,
    logoUrl: string,
    backgroundUrl: string,
    currency: string,
    date: string
  } = {
    title: '',
    logoUrl: '',
    backgroundUrl: '',
    currency: 'BRL',
    date: ''
  };

  charts: ChartModel[] = [];

}
