import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appChartdirective]'
})
export class ChartdirectiveDirective {

  constructor(el: ElementRef) {

  }

}
