import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { SqlToolsDialog } from "./sql_tools/sql_tools.dialog";
import { CustomDialog } from "./sql_tools/custom_dialog";
import { AppService } from "src/app/app.service";
import { TotalReportDialog } from "./sql_tools/total_report.dialog";


@Component({
    selector: 'app-user-details',
    templateUrl: './user_details.component.html'
})

export class UserDetailsComponent implements OnInit {

    userId: any = null;
    isUser = true
    selectedEdit = '';
    userInfo: any = {};
    masterMachine: any = {};
    isMastersMaster = false;
    constructor(private http: HttpClient, private dialog: MatDialog, private appService: AppService, private route: ActivatedRoute, private router: Router) {

    }

    ngOnInit() {
        this.userId = this.route.params.subscribe(data => {
            this.userId = data.id
            this.http.get<any>('/auth/super/restricted/user/' + this.userId).subscribe(data => {
                if (window.location.hostname.includes('touchefs.com')){
                    this.isMastersMaster = true;
                }
                this.userInfo = data;
                this.isUser = this.userInfo.type == 'user';
                this.masterMachine = data.machines[0];
            })
        })

       
    }

    addNewDb() {
        this.http.get('/auth/super/restricted/database/create/' + this.userInfo._id).subscribe(data => {
            this.userInfo.machines.unshift(data);
        })
    }

    addNewEvent() {

    }

    editMachine(machineId) {
        if (this.selectedEdit === '') {
            this.selectedEdit = machineId;
        } else {
            window.alert('As alterções não serão salvas')
        }
    }

    

    saveMachine(machineId) {
        this.http.put('/auth/super/restricted/database/' + this.userInfo._id + '/' + machineId, this.userInfo.machines.filter(item => item._id === machineId)[0]).subscribe(data => {
            this.selectedEdit = '';
        })
    }

    
    sqlTools(database) {
        let dialogRef = this.dialog.open(SqlToolsDialog, {
            width: '80%',
            data: {database: database}
        })

        dialogRef.afterClosed().subscribe(data => {
            
        }) 
    }

    cloneDb() {

    }

    deleteDb(machineId) {
        this.http.delete<any>('/auth/super/restricted/database/' + this.userInfo._id + '/' + machineId).subscribe(data => {
            console.log(machineId, 'machineID');
            if (data.status == 3) {
                this.userInfo.machines = this.userInfo.machines.filter(item => {
                   return item._id !== machineId
                })
            }else {
                this.userInfo.machines = this.userInfo.machines.filter(item => {
                   return item._id !== machineId
                })
            }
            
        }, err => {
            window.alert('Erro ao deletar banco de dados')
        })
    }


    createChildren(item) {
        let dialogRef = this.dialog.open(CustomDialog, {
            width: '65%',
            data: {
                title: 'Quantidade de Máquinas',
                method: 'get',
                endpoint: '/auth/super/restricted/database/create/child',
                params: '',
                body: {},
                queries: '?masterId=' + item._id,
                fields: [
                    {
                        name: 'children',
                        display: 'Quantidade',
                        value: 1,
                        type: 'number',
                        elementType: 'query'
                    }
                ]
            }
        })

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.userInfo.machines = this.userInfo.machines.concat(data);
            }
        })
    }


    openTotalReport(machine) {
        let isMaster = machine.type === 'PDV_master';
        let url = '/auth/super/event/report';
        let dbId = machine._id;
        let event = this.userInfo;
        event.date = this.masterMachine.date;
        let dialogRef = this.dialog.open(TotalReportDialog, {
            width: '70%',
            data: {isMaster: isMaster, url: url, dbId: dbId, title: machine.name, event: event}
        })

        dialogRef.afterClosed().subscribe(data => {

        })
    }

    backToEvents() {
        this.router.navigateByUrl('/super/users/' + (this.isUser ? '1' : '0') );
    }


    goReports(item) {
        localStorage.setItem('masterId', item._id);
        this.appService.setMaster(this.userInfo.machines);
        setTimeout(() => {
            this.router.navigateByUrl('/super/reports');

        }, 150) 
    }

    goCrud(item) {
        localStorage.setItem('masterId', item._id);
        this.appService.setMaster(this.userInfo.machines);
        setTimeout(() => {
            this.router.navigateByUrl('/super/crud/menu-item');

        }, 150) 
    }



}