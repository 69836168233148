import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class AuthService {

  token: string;

  isAuthenticated = false;

  onAuthEvent: EventEmitter<{
    event: string,
    isAuthenticated: boolean,
    wasAuthenticated: boolean
  }> = new EventEmitter();

  constructor() {

    this.checkToken();

  }

  checkToken() {

    if (this.getToken()) {

      this.isAuthenticated = true;

      this.onAuthEvent.emit({
        event: 'initLogin',
        isAuthenticated: true,
        wasAuthenticated: true
      });

    } else {

      this.isAuthenticated = false;

      this.onAuthEvent.emit({
        event: 'initLogout',
        isAuthenticated: false,
        wasAuthenticated: false
      });


    }

  }

  getToken() {
    return window.localStorage.getItem('_token');
  }

  setToken(token: string) {

    if (token === null) {

      window.localStorage.removeItem('_token');

      /*this.onAuthEvent.emit({
        wasAuthenticated: this.getToken() !== null,
        event: 'logout',
        isAuthenticated: false
      });*/

    } else {

      window.localStorage.setItem('_token', token);/*

      this.onAuthEvent.emit({
        wasAuthenticated: this.getToken() !== null,
        event: 'login',
        isAuthenticated: true
      });*/

    }

  }


}
