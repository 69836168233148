import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppService } from "../app.service";
import { MatDialog } from "@angular/material";
import { CustomReport } from "./dialogs/custom_report.dialog";


@Component({
    selector: 'app-reports-component',
    templateUrl: './reports.component.html'
})

export class ReportsComponent implements OnInit {

    notSuper = true;
    machines = [];
    userInfo: any = {};
    masterMachine:any = {};
    reportsList = [
        {
            name: 'Recebimentos',
            description: 'Relatório geral de recebimentos por tipo',
            labels: [{label: 'Descrição', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '1'}

            ],
            endpoint: '/auth/super/event/payments/report/' + localStorage.getItem('masterId'),
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Recebimentos por Máquina',
            description: 'Relatório de recebimentos por máquina',
            labels: [{label: 'Descrição', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'machineId', type: 'multi', method: 'params', placeholder: 'Máquina', multiVal: '_id', multiShow: 'name', multiList: this.machines, userInput: true, required: true, initialValue: null},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '0'}

            ],
            endpoint: '/auth/super/event/payments/report',
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Vendas PagSeguro',
            description: 'Relatório geral de recebimentos em PagSeguro por venda',
            labels: [{label: 'Descrição', value: 'name'},{label: 'Criação', value: 'creationDate'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '1'}

            ],
            endpoint: '/auth/super/event/pagseguro/report/' + localStorage.getItem('masterId'),
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Vendas PagSeguro por Máquina',
            description: 'Relatório de recebimentos em PagSeguro por máquina',
            labels: [{label: 'Descrição', value: 'name'},{label: 'Criação', value: 'creationDate'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'machineId', type: 'multi', method: 'params', placeholder: 'Máquina', multiVal: '_id', multiShow: 'name', multiList: this.machines, userInput: true, required: true, initialValue: null},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '0'}

            ],
            endpoint: '/auth/super/event/pagseguro/report',
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Vendas',
            description: 'Relatório de vendas geral',
            labels: [{label: 'Descrição', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '1'}

            ],
            endpoint: '/auth/super/event/report/' + localStorage.getItem('masterId'),
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Vendas por Máquina',
            description: 'Relatório de vendas por máquina',
            labels: [{label: 'Descrição', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'machineId', type: 'multi', method: 'params', placeholder: 'Máquina', multiVal: '_id', multiShow: 'name', multiList: this.machines, userInput: true, required: true, initialValue: null},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '0'}
            ],
            endpoint: '/auth/super/event/report',
            method: 'get',
            isFor: ['pdv', 'sales']
        },
         {
            name: 'Vendas por Grupo',
            description: 'Relatório de vendas geral por grupo',
            labels: [{label: 'Grupo', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '1'}

            ],
            endpoint: '/auth/super/event/sales/groups/' + localStorage.getItem('masterId'),
            isFor: ['pdv', 'sales'],
            method: 'get'
        },
        {
            name: 'Vendas por Grupo por Máquina',
            description: 'Relatório de vendas por grupo por máquina',
            labels: [{label: 'Grupo', value: 'name'}, {label: 'Qdade', value: 'qty'}, {label: 'Total', value: 'total'}],
            params: [
                {name: 'initialDate', type: 'date', method: 'query', placeholder: 'Data Inicial', userInput: true, required: true, initialValue: new Date()},
                {name: 'finalDate', type: 'date', method: 'query', placeholder: 'Data Final', userInput: true, required: true, initialValue: new Date()},
                {name: 'machineId', type: 'multi', method: 'params', placeholder: 'Máquina', multiVal: '_id', multiShow: 'name', multiList: this.machines, userInput: true, required: true, initialValue: null},
                {name: 'isMaster', type: 'string', method: 'query', placeholder: 'Master', userInput: false, required: true, initialValue: '0'}
            ],
            endpoint: '/auth/super/event/sales/groups',
            method: 'get',
            isFor: ['pdv', 'sales']
        }

    ];

    constructor(private http: HttpClient, private appService: AppService, private dialog: MatDialog) {

    }

    ngOnInit() {
        this.notSuper = this.appService.isPdvMaster;

        this.http.get<any>('/auth/super/machines/' + localStorage.getItem('masterId')).subscribe(data => {

            this.machines = data;
            this.http.get<any>('/auth/super/user/bymaster/' + localStorage.getItem('masterId')).subscribe(data => {
                this.userInfo = data.user;
                this.masterMachine = data.master;
            })
            
        })
    }


    backToSuper() {
        window.history.back()
    }

    generateReport(report) {
        let filts = report.params.filter(item => item.type == 'date')
        let hasDates = filts.length > 0;
        let event = this.userInfo;

        report.hasDates = hasDates;
        event.date = this.masterMachine.date;
        event.machineName = this.masterMachine.name
        report.machines = this.machines;
        report.event = event;
        report.params.map(item =>  {
            if (item.type == 'multi') {
                item.multiList = this.machines;
                return item
            } else {
                return item
            }
        })
        let dialogRef = this.dialog.open(CustomReport, {
            width: '60%',
            data: report
        })

        dialogRef.afterClosed().subscribe(data => {

        })
    }

    fetchReports() {
        // TODO implement fetch reports if the available reports will be on the server

    }


}