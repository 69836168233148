import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppService {
    superLogged = false;
    partners = [
        {
            wide_logo: "https://ingressos.nyc3.digitaloceanspaces.com/images/db-5c41d203766fd5751b91c2a1/ldsbmbdegieq0tqjpr9jd.png",
            compact_logo: "https://ingressos.nyc3.digitaloceanspaces.com/images/db-5c41d203766fd5751b91c2a1/8ta5c3f5x8819dzn3k3jbi.png",
            logoUrl: "https://ingressos.nyc3.digitaloceanspaces.com/images/db-5c41d203766fd5751b91c2a1/8ta5c3f5x8819dzn3k3jbi.png",
            name: 'Wise Vendas',
            host: 'wisepay.com.br',
            domain: 'wisepay.com.br',
            colorScheme: ['#e86922', '#095d8a']
        },
        {
            wide_logo: "assets/icon.png",
            compact_logo: "assets/icon.png",
            logoUrl: "assets/icon.png",
            name: 'TouChefs',
            host: 'touchefs.com',
            domain: 'touchefs.com',
            colorScheme: ['#336e89', '#264970']

        },
        {
            wide_logo: "assets/icon.png",
            compact_logo: "assets/icon.png",
            logoUrl: "assets/icon.png",
            name: 'TouChefs',
            host: 'localhost',
            domain: 'localhost',
            colorScheme: ['#336e89', '#264970']

        }
    ];
    
    isPdvMaster = false;
    currentPartner = null;
    
    constructor(private titleService: Title, private http: HttpClient) {
        
    }



  setSuperLogged(value) {
      this.superLogged = value;
  }

  getDirectPartner() {
    let hostname = window.location.hostname;
    return this.http.get<any>('/auth/super/white?hostname=' + hostname)
  }

  setMaster(machinesArray) {
    let filtered = machinesArray.filter(item => item.type == 'PDV_master');
    if (filtered.length > 0) {
        this.isPdvMaster = true;
        if (filtered[0]._id) {
            localStorage.setItem('masterId', filtered[0]._id);

        } else {
            localStorage.setItem('masterId', filtered[0].id);
        }
    } else {
    }
  }

  getCurrentPartner(cb) {
      if (this.currentPartner != null) {
        cb(this.currentPartner)
      }else {
        let hostname = window.location.hostname;
    
        this.http.get<any>('/auth/super/white?hostname=' + hostname).subscribe(data => {
            localStorage.setItem('partner', JSON.stringify(data))
            this.titleService.setTitle(data.name);
            document.querySelector<any>("link[rel*='icon']").href = data.logoUrl;
            this.currentPartner = data;
            cb(data);
        }, err => {
        });
      }

    /*  let hostname = window.location.hostname;

    let partner = this.partners.filter(item => hostname.includes(item.host));
    if (partner.length < 1) {    
        this.titleService.setTitle(this.partners[1].name);    
        document.querySelector<any>("link[rel*='icon']").href = partner[1].compact_logo;
        return this.partners[1]
    } else {
        this.titleService.setTitle(partner[0].name);
        document.querySelector<any>("link[rel*='icon']").href = partner[0].compact_logo;
        return partner[0]}*/
  }

}