

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-license-details-dialog',
  templateUrl: './license-details.dialog.html',
  styleUrls: ['./license-details.dialog.css']
})

export class LicenseDetailsDialog implements OnInit {


  constructor(public dialogRef: MatDialogRef<LicenseDetailsDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  confirmateDialog() {

  }


  closeDialog(): void {
    this.dialogRef.close();
  }


}
