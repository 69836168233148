

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-custom-report',
  templateUrl: './custom_report.dialog.html'
})

export class CustomReport implements OnInit {

    
    notSuper = true;
  
    phase = 1;
    selectedOperator:any = {initialValue: 'Evento'}
    isReady = false;
    responseList = [];

  constructor(public dialogRef: MatDialogRef<CustomReport>,
              private http: HttpClient,
              private appService: AppService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }



  ngOnInit() {
      this.notSuper = this.appService.isPdvMaster;
      console.log(this.data.event.machineName == this.data.event.name)
      if (this.data.auto) {
        this.generateDialog();
      }
  }


  backToPhase(phase) {
    this.phase = phase
  }

  mapMachine(match) {
    
    let filtered = this.data.machines.filter(item => {
      return item._id == match
    })

    if (filtered.length > 0) {
      return filtered[0].name
    }else {
      return 'NAO ENCONTRADO'
    }
  }

  generateShortDate(date) {
    let correctedDate = new Date(date);
    let day = correctedDate.getUTCDate().toString();
    let month = (correctedDate.getUTCMonth() + 1).toString();
    let year = correctedDate.getUTCFullYear().toString();
    return  year + '-' + (month.length < 2 ? '0' + month : month) + '-' +  (day.length < 2 ? ('0' + day) : day)
  }

  generateBrDate(date) {
    let correctedDate = new Date(date);
    let day = correctedDate.getUTCDate().toString();
    let month = (correctedDate.getUTCMonth() + 1).toString();
    let year = correctedDate.getUTCFullYear().toString();

    return  (day.length < 2 ? ('0' + day) : day) + '/' + (month.length < 2 ? '0' + month : month) + '/' + year
   // return  year + '-' + (month.length < 2 ? '0' + month : month) + '-' +  (day.length < 2 ? ('0' + day) : day)
  }

  generateDialog() {


    let initialDate = new Date()
    let finalDate = new Date()
    if (this.data.hasDates) {
      this.data.initialDate = this.data.params.filter(item => item.name == 'initialDate')[0].initialValue
      this.data.finalDate = this.data.params.filter(item => item.name == 'finalDate')[0].initialValue
      console.log('finished setting data dates')
    }


    console.log(this.data.initialDate, this.data.finalDate, initialDate, finalDate)
    let query = '';
    let body = {};
    let params = '';
    for (let itm of this.data.params) {
      if (itm.type == 'multi' && itm.name == 'machineId') {
        this.selectedOperator = {initialValue: itm.multiList.filter(machine => machine._id == itm.initialValue)[0].name}
      }
      if (itm.method == 'query') {
        query = query + (query.length > 0 ? '&' + itm.name + '=' + (itm.type == 'date' ? this.generateShortDate(itm.initialValue) : itm.initialValue) : '?' + itm.name + '=' +  (itm.type == 'date' ? this.generateShortDate(itm.initialValue) : itm.initialValue))
      }else {
        if (itm.method == 'body') {
          body[itm.name] = (itm.type == 'date' ? this.generateShortDate(itm.initialValue) : itm.initialValue);
        }else {
          params = params + '/' + itm.initialValue
        }
      }
    }

    if (this.data.method == 'post' || this.data.method == 'put') {
      console.log('before executing post-put report')

      this.http[this.data.method](this.data.endpoint + params + query, body).subscribe(data => {
        this.responseList = data;
        this.phase = 2;
        this.isReady = true;

      })

    }else {
      console.log('before executing get report')
      this.http[this.data.method](this.data.endpoint + params + query).subscribe(data => {
        this.responseList = data;
        this.phase = 2;
        this.isReady = true;

      })

    }
  }

  closeDialog( ) {
    this.dialogRef.close(null)

  }


  cancelDialog() {
      this.dialogRef.close(null)
  }

}
