import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ChartModel} from '../models/chart.model';
import {DecimalPipe} from "@angular/common";

declare var ApexCharts: any;

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit {

  @Input()
  chart: ChartModel;


  @ViewChild('chartDiv')
  chartDiv: ElementRef;

  @Input()
  currency: string = 'BRL';

  apex: any = null;

  constructor(private decimalPipe: DecimalPipe) { }


  isArray(item) {
    return Array.isArray(item)
  }

  ngOnInit() {

    this.chart.onNewData = (data: ChartModel) => {

      const temp = this.chart;

      this.chart = data;

      this.chart.onNewData = temp.onNewData;

      console.log('onNewData', data.title);

      if (this.apex !== null && typeof this.apex !== 'undefined') {

        console.log('call updateOptions');

        if(data !== null && typeof data !== 'undefined') {
          this.apex.updateOptions(data.options);

        }
      }
    };

  }





  ngAfterViewInit() {

    console.log(this.chart);


    setTimeout(() => {

      try {

        this.apex = new ApexCharts(this.chartDiv.nativeElement, this.chart.options);

        this.apex.render();

      } catch (err) {

        console.log(err);

      }

    }, 150);

  }

}
