

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-super-license-crud-dialog',
  templateUrl: './insert_update_license.dialog.html',
  styleUrls: ['./insert_update_license.dialog.css']
})

export class LicenseCrudDialog implements OnInit {


  license: any = {
    _id: '',
    name: '',
    description: '',
    multi: false,
    type: '',
    amount: 1,
    quantity: 1,
    internalId: 1,
    status: 0,
    partners: false,
    logoUrl: "https://www.cprime.com/wp-content/uploads/2015/05/3.png"
  }

  isNew = false;
  availableTypes = [{name: 'Mensal', value: 'montly'}, {name: 'Quantidade', value: 'quantity'}]
  constructor(public dialogRef: MatDialogRef<LicenseCrudDialog>,
              private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
      if (this.data.isNew) {
        this.isNew = true;
      }else {
        this.isNew = false;
        this.license = this.data.license

      }
  }



  showPartner() {
      this.license.partners = !this.license.partners
  }

  allowMulti(event) {
    this.license.multi = !this.license.multi;
}

  

  confirmDialog() {
    if(this.isNew) {
      this.createPartner()
    }else {
      this.updatePartner()
    }
  }

  createPartner() {
    this.http.post('/auth/licences/v2/', this.license).subscribe(data => {
      this.dialogRef.close(data);
    }, err => {
      window.alert('Erro na criação')
    })
  }

  updatePartner() {
    this.http.put('/auth/licences/v2/' + this.license._id, this.license).subscribe(data => {
      this.dialogRef.close(data);
    }, err => {
      window.alert('Erro na criação')
    })
  }



  closeDialog(): void {
    this.dialogRef.close(false);
  }


}

