import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material";
import {EnterpriseConfigurationsDialog} from "./dialogs/enterprise-configurations.dialog";

@Component({
  selector: 'app-list-enterprise',
  templateUrl: './list-enterprise.component.html',
  styleUrls: ['./list-enterprise.component.scss']
})
export class ListEnterpriseComponent implements OnInit {

  list: any[] = [];

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    this.http.get('/restricted/crud/enterprise').subscribe((next: any[]) => {
      this.list = next;
    });

  }

  add() {
    this.list.unshift({
      name: '',
      isNew: true,
    });
  }

  addPush() {

    this.list.push({
      name: '',
      isNew: true,
    });

  }

  clone(){

  }

  save(item: any) {

    if(!item.isNew){
      this.http.put('/restricted/crud/enterprise/' + item.remoteId, item).subscribe((next: any) => {
        item = next;
      });

    }else{
      this.http.post('/restricted/crud/enterprise', item).subscribe((next: any) => {
        item = next;
      });
    }

  }


  editConfig(enterprise) {
    let dialogRef = this.dialog.open(EnterpriseConfigurationsDialog, {
      width: '60%',
      data: {
        enterprise: enterprise
      }
    });
    dialogRef.afterClosed().subscribe(data => {

    })
  }

}
