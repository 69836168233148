import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-observations',
  templateUrl: './list-observations.component.html',
  styleUrls: ['./list-observations.component.css']
})
export class ListObservationsComponent implements OnInit {

  list: any[] = [];
  sending = false;
  notSuper = true;
  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {
    
    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }

    let masterId2 = this.notSuper ? '' : '/' + (localStorage.getItem('masterId')) 

    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';

    this.http.get(startingUrl + 'crud/observation' + masterId2).subscribe((next: any[]) => {

      this.list = next;
    })

  }

  add() {
    this.list.unshift({
      name: '',
      isNew: true,
    });
  }

  modify() {
    
  }

  deleteProductionPoint(item) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'

    this.http.delete<any>(startingUrl + 'crud/observation/' + masterId + item.remoteId).subscribe(data => {
      item.active = 0;
      setTimeout(() => {
        this.sending = false;
      }, 500)
    })
  }

  addPush() {

    this.list.push({
      name: '',
      isNew: true,
    });

  }

  clone(){

  }

  save(item: any) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 = this.notSuper ? '' : '/' + (localStorage.getItem('masterId')) 

    if(!item.isNew){
        delete(item.saving);
        delete(item.checked);
        delete(item.isNew);
        delete(item.selected);
        item.active = true;
        this.http.put(startingUrl +'crud/observation/' + masterId + item.remoteId, item).subscribe((next: any) => {
            item = next;
            setTimeout(() => {
              this.sending = false;
            }, 500)
        });
    } else {
        delete(item.saving);
        delete(item.checked);
        delete(item.isNew);
        delete(item.selected);
        item.active = true;
        this.http.post(startingUrl +'crud/observation' + masterId2, item).subscribe((next: any) => {
            item = next;
            setTimeout(() => {
              this.sending = false;
            }, 500)
        });
    }

  }


}
