import { Component, OnInit } from "@angular/core";
import {AppService} from "../app.service"


@Component({
    selector: 'app-policy-component',
    templateUrl: './policy.component.html'
})

export class PolicyComponent implements OnInit {
    partner = {name: 'PagPdv'}
    isLoading = true;
    constructor(private appService: AppService) {

    }


    ngOnInit() {
        this.appService.getCurrentPartner(newPartner => {
            this.partner = newPartner;
            this.isLoading = false;
          })

    }
    

    lwerCase(str) {
        return str.toLowerCase();
    }


} 