import {Component, OnInit} from '@angular/core';
import {SocketService} from './services/socket.service';
import {AuthService} from "./services/auth.service";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import { AppService } from './app.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'front';

  socketDestroyed = false;

  authenticated = false;

  machines: any[] = [];

  token = '';

  partner: any = {};
  
  sanitizedBackgroud = "background: #1565c0 url('../assets/css/bg.png');";

  notSuper = true;

  isLoading = true;

  backgroundGradient = ''
  constructor(private socket: SocketService,
              private auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: Title,
              public appService: AppService,
              private snack: MatSnackBar) {

    this.auth.onAuthEvent.subscribe((val) => {


      this.authenticated = val.isAuthenticated;

      if (val.isAuthenticated) {

          //  this.router.navigateByUrl('/dashboard');


      } else {

        // TODO delete this condition
        if (this.router.url !== '/licenses' && !this.router.url.includes('/super')) {
         // this.router.navigateByUrl('/');
        }

      }

    });

    this.auth.checkToken();

    this.router.events.subscribe((r) => {
      this.notSuper = !this.router.url.includes('super');
      this.authenticated = window.localStorage.getItem('_token') !== null;
      this.token = window.localStorage.getItem('_token');
      this.machines = JSON.parse(window.localStorage.getItem('_machines'));
    });

  }


  ngOnInit() {

    //this.backgroundGradient = 'linear-gradient(150deg, '+this.partner.colorScheme[0]+' -21%,'+this.partner.colorScheme[1]+' 68%, '+this.partner.colorScheme[0]+' 114%)'


  this.partner = JSON.parse(localStorage.getItem('partner'));

    this.appService.getCurrentPartner(newPartner => {
      this.partner = newPartner;
      this.backgroundGradient = 'linear-gradient(to bottom right, '+this.partner.colorScheme[0]+', '+this.partner.colorScheme[1]+')';
      console.log('got pnr');
      this.isLoading = false;
    })

    //this.backgroundGradient = 'linear-gradient(to bottom right, '+this.partner.colorScheme[0]+', '+this.partner.colorScheme[1]+')';
    this.socket.onSocketDestroyed.subscribe(() => {

      this.socketDestroyed = true;

    });

    this.socket.onMessage('server_connected', (m) => {

      this.snack.open('Servidor conectado!', 'OK', {
        duration: 1000
      });

    });


    

    this.socket.onMessage('server_disconnected', (m) => {

      this.snack.open('Servidor offline!', 'OK', {
        duration: 1000
      });

    });

  }


  scrollToTop() {
    window.scrollTo(0,0)
  }


  logout() {

    this.auth.setToken(null);
    localStorage.removeItem('_temp_token');
    localStorage.removeItem('partner');
    localStorage.removeItem('token');
    localStorage.removeItem('_machines');
    localStorage.removeItem('masterId');
    this.router.navigate(['/']);

    return false;

  }

  updateAndReload() {

    window.localStorage.setItem('_token', this.token);
    window.localStorage.setItem('masterId', this.machines.filter(item => item.token == this.token)[0].id)

    window.location.reload();

  }


}
