import { Component, OnInit } from '@angular/core';
import {SocketService} from '../../services/socket.service';

import uuid from 'uuid/v4';
import Swal from 'sweetalert2';
import {MatDialog, MatDialogRef} from "@angular/material";
import {ModifyDialogComponent} from "./modify-dialog.component";
import {HttpClient} from "@angular/common/http";
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-list-menu-item',
  templateUrl: './list-menu-item.component.html',
  styleUrls: ['./list-menu-item.component.css']
})
export class ListMenuItemComponent implements OnInit {

  list: any[] = [];

  saving = false;

  productionPoints: any[] = [];

  groups: any[] = [];

  sending = false;
  notSuper = true;
  constructor(

    private rest: HttpClient,

    private socket: SocketService,
    private appService: AppService,
    private matDialog: MatDialog,
    private router: Router

  ) {

  }

  changeImage(item) {

  }

  updateGroups() {

  }

  deleteMenuItem(item) {
    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    this.sending = true;
    this.rest.delete<any>(startingUrl + 'crud/menuitem/' + masterId + item.remoteId).subscribe(data => {
      item.active = 0;
      setTimeout(() => {
        this.sending = false;
      }, 500)
    })
  }


  onSelectFile(files, menuItem) {
    let file = files.files[0];
    let fileName = files.files[0].name.toLowerCase();
    if (file.size > 2000000) {
      window.alert('File is too big');
      files.value = "";
    } else {

      if ((fileName.endsWith('png') === true) || (fileName.endsWith('jpg') === true) || (fileName.endsWith('jpeg') === true)) {
        if (file !== undefined) {
          let image = file;
          let formData = new FormData();
          formData.append('file', image);
           this.rest.post<any>('http://www.seurestaurante.com/api/public/file', formData).subscribe(data => {
              menuItem.imageUrl = data.url;

            },
            error => {
              //handdle error
            });
        } else {
        }
      }else{
        files.value = "";
        window.alert('Formato incorreto')
      }
    }
  }




  save(item: any) {
    this.sending = true;
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 = this.notSuper ? '' : '/' + (localStorage.getItem('masterId'))
    if (item.productionPointId === null) {
      item.productionPointId = "";
    }
    if (item.saving) return;

    item.saving = true;

    const obj = JSON.parse(JSON.stringify(item));

    const isNew = item.isNew;

    delete(obj.saving);
    delete(obj.checked);
    delete(obj.isNew);
    delete(obj.selected);

    if(!isNew) {
      item.price.toString()
      this.rest.put(startingUrl + 'crud/menuitem/'+ masterId + obj.remoteId, obj).subscribe((res: any) => {

        item.selected = null;

        this.updateGroups();

        setTimeout(() => {
          this.sending = false;
        }, 500);

        setTimeout(() => {
          item.saving = false;
        }, 500);

      }, () => {
      });

    } else {

      this.rest.post( startingUrl + 'crud/menuitem' + masterId2, obj).subscribe((res: any) => {

        item.isNew = false;

        item.selected = null;

        console.log('object.assing', item, res);

        Object.assign(item, res);

        item.saving = true;

        console.log('new_val', item);

        setTimeout(() => {
          this.sending = false;
        }, 500);

        this.updateGroups();

          setTimeout(() => {

            console.log('timeout end');

            item.saving = false;
          }, 1500);


      }, () => {
      });



    }


  }

  add() {

   this.list.unshift({
     price: 0,
     name: '',
     groupName: '',
     isNew: true,
     active: 1,
     imageUrl: 'http://192.168.0.15:3434/dashboard/assets/icon.png',
     selected: true,
     remoteId: uuid()
   });

  }

  ngOnInit() {

    /*this.socket.emit('get_data', {
      table: 'menuitem'
    }, () => {

      console.log('data_check', 'OK');

      this.socket.onMessage('got_data', (data: any[]) => {

        console.log('data_found', data);

        this.list = data;

        this.updateGroups();

        this.socket.offMessage('got_data');


        setTimeout(() => {

          this.socket.emit('get_data', {
            table: 'productionpoint'
          }, () => {

            console.log('data_check', 'OK');

            this.socket.onMessage('got_data', (pps: any[]) => {

              console.log('data_found.pps', pps);

              this.productionPoints = pps;

            });
          });

        }, 4000);

      });
    });*/

    if (this.router.url.includes('super/') === true || this.appService.isPdvMaster === true) {
      this.notSuper = false;
    }
    let startingUrl = this.notSuper ? '/restricted/' : '/pdv/';
    let masterId = this.notSuper ? '' : (localStorage.getItem('masterId')) + '/'
    let masterId2 =  this.notSuper ? '' : '/' + (localStorage.getItem('masterId')) 


    let x = (this.router.url.includes('super/') ? '/pdv/' : '/restricted/')

    this.rest.get(startingUrl+'crud/menuitem' + masterId2).subscribe((all: any[]) => {

      this.list = all;

      this.rest.get(startingUrl+'crud/productionpoint' + masterId2).subscribe((prods: any[]) => {
        this.productionPoints = prods;
        this.productionPoints.push({id: "", type: "printer", name: "Sem Impressora", remoteId: ""})
      });

      this.rest.get(startingUrl+'crud/menuitemgroup' +masterId2).subscribe((groups: any[]) => {
        this.groups = groups;
      });

    });



  }

  clone() {

    console.log('clone');

    const toClone = JSON.parse(JSON.stringify(this.list.filter(a => a.checked)));

    console.log('items', toClone);

    if (toClone.length === 0) {

      Swal.fire(
        'Oops!',
        'Não tem items. Selecione os items a clonar.',
        'error'
      );

    } else {

      this.list.forEach((a, b) => {
        if(!this.list[b]) return;
        this.list[b].checked = false;
      });

     toClone.reverse().map(a => {

        a.id = null;

        a.checked = true;

        a.isNew = true;

        a.remoteId = uuid();

        this.list.unshift(a);

        return a;

      });

    }

  }

  addPush() {

    this.list.push({
      price: 0,
      name: '',
      groupName: '',
      active: 1,
      imageUrl: 'http://192.168.0.15:3434/dashboard/assets/icon.png',
      selected: true,
      isNew: true,
      remoteId: uuid()
    });

  }

  modify() {

    const dialogRef = this.matDialog.open(ModifyDialogComponent, {
      width: '50%',
      data: {

      },
    });

    dialogRef.afterClosed().subscribe((next: any) => {

      if(next !== null) {

        console.log('modified', next);

        this.list.forEach((a, index) => {

          if (a.checked) {

            if (next.isGroup) {
              this.list[index].menuItemGroupId = next.group;
            }

            if (next.isPrice) {
              this.list[index].price = next.price;
            }

          }

        });

      }

      });

  }

  selectGroup(group: string){

    this.list.forEach((ite, k) => {
      ite.checked = false;
    });

    this.list.forEach((ite, k) => {

      if(ite.menuItemGroupId === group){
        ite.checked = true;
      }

    });

  }

}
