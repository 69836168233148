import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PartnerDetailsDialog } from './partner-details/partner_details.dialog';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: []
})
export class PartnersComponent implements OnInit {

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router) { }
  partnersList: any = []
  searchTerm = '';
  pagingData = {page: 1, size: 25, total: 100}

  ngOnInit() {
    this.getPartners();
  }

  getPartners() {
    this.http.get<any>('/auth/super/restricted/partners?page=' + this.pagingData.page + '&limit=' + this.pagingData.size).subscribe(data => {
      this.partnersList = data.docs;
      this.pagingData.total = data.count || 100;
    })
    this.http.get<any>('/auth/super/restricted/partners/v2').subscribe(data => {
      console.log('NEW VERSION WITH PROJECTION', data)
    })
  }


  onSearch() {
    this.http.get('/auth/super/restricted/partner/find/' + this.searchTerm).subscribe(data => {
      this.partnersList = data;
    })
  }


  editPartner(partner) {
    let dialogRef = this.dialog.open(PartnerDetailsDialog, {
      width: '90%',
      disableClose: true,
      data: {isNew: false, partner: partner}
    })
    
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.partnersList.map(item => {
          if (item._id === data._id) {
            return data
          }else {
            return item
          }
        })
      }
    })
  }

  insertPartner() {
    let dialogRef = this.dialog.open(PartnerDetailsDialog, {
      width: '90%',
      disableClose: true,
      data: {isNew: true}
    })

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.partnersList.unshift(data)
      }
    })
  }

  deletePartner(partnerId) {
    this.http.delete('/auth/super/restricted/partner/' + partnerId).subscribe(data => {
      this.partnersList = this.partnersList.filter(item => item._id !== partnerId);
    }, err => {
      window.alert('Erro ao excluir parceiro')
    })
  }

  changePaging(event) {
    console.log(event);
    this.pagingData.size = event.pageSize;
    this.pagingData.page = event.pageIndex + 1;
    this.getPartners();
  }

}
