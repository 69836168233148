import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-machine-dialog',
  templateUrl: './select-machine-dialog.component.html',
  styleUrls: ['./select-machine-dialog.component.scss']
})
export class SelectMachineDialogComponent implements OnInit {

  machines: any[] = [];

  constructor(
    private ref: MatDialogRef<SelectMachineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private router: Router
  ) {

    this.machines = this.data.machines;

  }

  ngOnInit() {
  }

  select(machine: any) {

    window.localStorage.setItem('_token', machine.token);
    window.localStorage.setItem('masterId', machine.id)
    this.ref.close();

    this.router.navigate(['/dashboard'], {
      queryParams: {
        machine: machine._id,
      }
    });

  }


}
